import { Images } from '~data'
const featureSectionData = {
    featureTabTitle: "",
    tabWidget: [
        {
            icon: Images.projectManagement.featureTwoIcon1,
            iconBackground: "#ff8d52",
            title: "About Us",
        },
        {
            icon: Images.projectManagement.featureTwoIcon2,
            iconBackground: "#15cda8",
            title: "Services",
        },
        {
            icon: Images.projectManagement.featureTwoIcon1,
            iconBackground: "#ff8d52",
            title: "Contact Information",
        },

    ],


}

export default featureSectionData;